export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/authentication/login'),
    meta: {
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/signup',
    name: 'signUp',
    component: () => import('@/views/authentication/signup'),
    meta: {
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
];
