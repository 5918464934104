import Vue from "vue";
import Vuex from "vuex";

// Modules
import langStore from './langStore';


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    langStore,
  },
  strict: process.env.DEV,
});
