<template>
  <div class="transactions-page">
    <b-row class="m-0">
      <pageHeader
        :icon="pageHead.icon"
        :headlineTranslationKey="pageHead.headlineTranslationKey"
        :textTranslationKey="pageHead.textTranslationKey"
      />
    </b-row>
    <b-row class="servies-list">
      <serviceItem
        v-for="(item, index) in services"
        :key="index"
        :icon="item.icon"
        :serviceName="item.name"
        :serviceProvider="item.provider"
        :myTransaction="true"
        :date="item.date"
        class=""
      />
      <span class="see-more">{{ $t('g.pages/myTransactions/seemore') }}</span>
    </b-row>
  </div>
</template>
<script>
import { BRow, BLink } from 'bootstrap-vue';
import pageHeader from '@/components/shared/pageHeader';
import serviceItem from '@/components/shared/serviceItem';
export default {
  data() {
    return {
      pageHead: {
        icon: require('@/assets/image/pages/myTransactions/pageHeadIcon.png'),
        textTranslationKey: 'pages/myTransactions/pageHead/text',
        headlineTranslationKey: 'pages/myTransactions/pageHead/headline',
      },
      services: [
        {
          icon: require('@/assets/image/pages/myTransactions/icons/health.png'),
          name: 'تأمين عمالة',
          provider: 'وزارة الصحة',
          date: '1444/10/18',
        },
        {
          icon: require('@/assets/image/pages/myTransactions/icons/badge.png'),
          name: 'بلاغ مقدم',
          provider: 'وزاره الداخلية',
          date: '1444/10/1',
        },
        {
          icon: require('@/assets/image/pages/myTransactions/icons/traffic-lights.png'),
          name: 'الإدارة العامة للمرور',
          provider: 'وزارة الصحة',
          date: '1444/10/25',
        },
        {
          icon: require('@/assets/image/pages/myTransactions/icons/health.png'),
          name: 'تأمين عمالة',
          provider: 'وزارة الصحة',
          date: '1444/10/18',
        },
        {
          icon: require('@/assets/image/pages/myTransactions/icons/traffic-lights.png'),
          name: 'تجديد رخصة قيادة',
          provider: 'الإدارة العامة للمرور',
          date: '1444/10/1',
        },
        {
          icon: require('@/assets/image/pages/myTransactions/icons/real-estate.png'),
          name: 'تملك عقار',
          provider: 'وزاره الإستثمار',
          date: '1444/10/25',
        },
      ],
    };
  },
  components: {
    BRow,
    pageHeader,
    serviceItem,
    BLink,
  },
  created() {
    this.$i18n.locale = 'ar';
  },
};
</script>
<style lang="scss">
@import './_index';
</style>
