<template>
  <div class="pageHeader">
    <b-col class="ico-frame">
      <img :src="icon" alt="ixom" />
    </b-col>
    <b-col class="speach">
      <div class="header row">{{ $t(`g.${headlineTranslationKey}`) }}</div>
      <div class="text mt-4">
        {{ $t(`g.${textTranslationKey}`) }}
      </div>
    </b-col>
  </div>
</template>
<script>
import { BCol } from 'bootstrap-vue';
export default {
  props: ['headlineTranslationKey', 'textTranslationKey', 'icon'],
  components: {
    BCol,
  },
};
</script>
<style lang="scss">
@import './_index.scss';
</style>
