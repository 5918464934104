<template>
  <div class="makePaymentComponent">
    <b-col class="d-flex justify-content-center">
      <img :src="icons.fullCradIcon" class="card" />
    </b-col>
    <b-col class="col-lg-5 col-md-10 col-sm-10 form-box">
      <validation-observer ref="payment-form " class="payment-form">
        <b-form class="form">
          <b-form-group label-for="cardNum" class="w-100 mb-4">
            <validation-provider #default="{ errors }" name="idNum" rules="required">
              <div class="d-inline">
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('g.makepayment/form/placeholders/cardNumber')"
                  type="text"
                />
                <img :src="icons.id" alt="" class="small-ico" />
              </div>
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="cardNum" class="w-100 mb-4">
            <validation-provider #default="{ errors }" name="idNum" rules="required">
              <div class="d-inline">
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('g.makepayment/form/placeholders/username')"
                  type="text"
                />
                <img :src="icons.person" alt="" class="small-ico" />
              </div>
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="cardNum" class="w-100 mb-4">
            <validation-provider #default="{ errors }" name="idNum" rules="required">
              <div class="d-inline">
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('g.makepayment/form/placeholders/expDate')"
                  type="text"
                />
                <img :src="icons.calender" alt="" class="small-ico" />
              </div>
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small>
            </validation-provider>
          </b-form-group>
          <p class="sweet-text">
            {{ $t('g.componts/makepayments/text') }}
          </p>
          <b-link :to="{ name: 'Otp' }">
            <b-button variant="primary" class="w-75 align-self-center btn">
              {{ $t('g.makepayment/form/buttons/send') }}
            </b-button>
          </b-link>
        </b-form>
      </validation-observer>
    </b-col>
  </div>
</template>
<script>
import { BForm, BFormGroup, BFormInput, BCol, BButton, BLink } from 'bootstrap-vue';
import { required, password } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
export default {
  components: {
    BForm,
    BLink,
    BFormGroup,
    BFormInput,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BButton,
  },
  data() {
    return {
      icons: {
        id: require('@/assets/icons/credit-card.svg'),
        person: require('@/assets/icons/account.svg'),
        calender: require('@/assets/icons/calendar.svg'),
        fullCradIcon: require('@/assets/icons/Card 2.svg'),
      },
    };
  },
};
</script>
<style lang="scss">
@import './index';
</style>
