export default [
  {
    path: '/report',
    name: 'Report',
    component: () => import('@/views/report/report/index.vue'),
    meta: {
      layout: 'withLayout',
    },
  },
  {
    path: '/report/view-details',
    name: 'ViewReportDetails',
    component: () => import('@/views/report/viewReportDetails/index.vue'),
    meta: {
      layout: 'withLayout',
    },
  },
];
