<template>
  <b-link
    :to="!myTransaction ? { name: 'showService', params: { id: serviceName } } : null"
    class="text-decoration-none service-item"
  >
    <div class="serviceItem">
      <div class="icon">
        <img :src="icon" alt="Service-icon" />
      </div>
      <div class="content">
        <span class="service-name">{{ serviceName }}</span>
        <span class="service-provider">{{ serviceProvider }}</span>
        <span class="service-price" v-if="!myTransaction"
          >{{ servicePrice }} {{ $t('g.realsar') }}</span
        >
        <span class="service-price" v-if="myTransaction">{{ date }}</span>
      </div>
    </div>
  </b-link>
</template>
<script>
import { BLink } from 'bootstrap-vue';
export default {
  props: ['icon', 'serviceName', 'serviceProvider', 'servicePrice', 'myTransaction', 'date'],
  data() {
    return {
      dicon: require('@/assets/styles/img/health.png'),
    };
  },
  components: {
    BLink,
  },
};
</script>
<style lang="scss">
@import './index';
</style>
