<template>
  <div class="show-service">
    <b-row class="w-100 m-0">
      <pageHeader
        class="w-100"
        :icon="pageHeader.icon"
        :textTranslationKey="pageHeader.textTranslationKey"
        :headlineTranslationKey="pageHeader.headlineTranslationKey"
      />
    </b-row>
    <b-row class="w-100 m-0">
      <showService :data="data" />
    </b-row>
  </div>
</template>
<script>
import pageHeader from '@/components/shared/pageHeader';
import showService from '@/components/showService';
import { BRow } from 'bootstrap-vue';
export default {
  components: {
    pageHeader,
    BRow,
    showService,
  },
  data() {
    return {
      pageHeader: {
        icon: require('@/assets/image/pages/showService/pageHeaderIcon.png'),
        textTranslationKey: 'pages/showService/pageHead/text',
        headlineTranslationKey: 'pages/showService/pageHead/headline',
      },
      WholeData: [
        {
          icon: require('@/assets/image/pages/showService/icons/health.png'),
          name: 'تأمين عمالة',
          provider: 'وزارة الصحة',
          date: '1444/10/18',
          price: 20,
        },
        {
          icon: require('@/assets/image/pages/showService/icons/badge.png'),
          name: 'بلاغ مقدم',
          provider: 'وزاره الداخلية',
          date: '1444/10/1',
          price: 20,
        },
        {
          icon: require('@/assets/image/pages/showService/icons/traffic-lights.png'),
          name: 'الإدارة العامة للمرور',
          provider: 'وزارة الصحة',
          date: '1444/10/25',
          price: 20,
        },
        {
          icon: require('@/assets/image/pages/showService/icons/health.png'),
          name: 'تأمين عمالة',
          provider: 'وزارة الصحة',
          date: '1444/10/18',
          price: 20,
        },
        {
          icon: require('@/assets/image/pages/showService/icons/traffic-lights.png'),
          name: 'تجديد رخصة قيادة',
          provider: 'الإدارة العامة للمرور',
          date: '1444/10/1',
          price: 20,
        },
        {
          icon: require('@/assets/image/pages/showService/icons/real-estate.png'),
          name: 'تملك عقار',
          provider: 'وزاره الإستثمار',
          date: '1444/10/25',
          price: 20,
        },
      ],
      data: null,
    };
  },

  created() {
    this.WholeData.forEach((ele) => {
      if (ele.name == this.currentName) {
        return (this.data = ele);
      }
    });
  },
  computed: {
    currentName() {
      return this.$route.params.id;
    },
  },
};
</script>
