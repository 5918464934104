<template>
  <section class="latest-transactions-section">
    <div class="text-side">
      <p class="text-body">{{ $t('g.home/latest_transactions_section/view_all') }}</p>
      <h2 class="text-title">
        {{ $t('g.home/latest_transactions_section/latest_transactions') }}
      </h2>
    </div>
    <div class="cards-side">
      <serviceItem
        v-for="(_, index) in servicesLength"
        :icon="badgeImage"
        :key="index"
        serviceName="بلاغ مقدم"
        serviceProvider="وزاره الداخلية"
        date="1444/10/1"
      />
    </div>
  </section>
</template>

<script>
import { BLink } from 'bootstrap-vue';
import serviceItem from '../../shared/serviceItem';
export default {
  name: 'AboutReferncesSection',
  components: {
    BLink,
    serviceItem,
  },
  data() {
    return {
      servicesLength: [1, 2, 3, 4],
      sectionImage: require('@/assets/images/about-references-image.svg'),
      badgeImage: require('@/assets/images/badge.svg'),
    };
  },
};
</script>

<style lang="scss">
@import './_index.scss';
</style>
