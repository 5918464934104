import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/home';
import Fees from '@/views/fees';
import Otp from '@/views/otpPage';
import makePayment from '@/views/makePayment';

import myTransactions from '@/views/myTransactions';
import showService from '@/views/showService';

// routers
import authentication from './authentication';
import report from './report';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      layout: 'withLayout',
      pageRoot: 'home',
    },
  },
  {
    path: '/fees',
    name: 'Fees',
    component: Fees,
    meta: {
      layout: 'withLayout',
      pageRoot: 'fees',
    },
  },
  {
    path: '/my-transactions',
    name: 'MyTransactions',
    component: myTransactions,
    meta: {
      layout: 'withLayout',
      pageRoot: 'myTransactions',
    },
  },
  {
    path: '/otp',
    name: 'Otp',
    component: Otp,
    meta: {
      layout: 'withLayout',
      pageRoot: 'fees',
    },
  },
  {
    path: '/make-payment',
    name: 'makePayment',
    component: makePayment,
    meta: {
      layout: 'withLayout',
      pageRoot: 'fees',
    },
  },
  {
    path: '/show-service/:id',
    name: 'showService',
    component: showService,
    meta: {
      layout: 'withLayout',
      pageRoot: 'fees',
    },
  },
  ...authentication,
  ...report,
];

const router = new VueRouter({
  routes,
});

export default router;
