<template>
  <div id="app">
    <main-layout v-if="isLayout" />
    <router-view v-else />
  </div>
</template>

<script>
const mainLayout = () => import('@/layouts/mainLayout');
export default {
  name: 'App',
  components: {
    mainLayout,
  },
  mounted() {
    if (localStorage.getItem('morajea_lang')) {
      this.$store.dispatch('UPDATE_APP_LANG', localStorage.getItem('morajea_lang'));
    } else {
      this.$store.dispatch('UPDATE_APP_LANG', 'ar');
    }
  },
  computed: {
    isLayout() {
      if (this.$route.meta.layout === 'withLayout') {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/custom-vars.scss';
body {
  font-family: $font-family !important;
}
</style>
