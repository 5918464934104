<template>
  <div class="show-service px-4 row">
    <b-col class="icon-side col-lg-5 col-md-11 col-sm-11">
      <img :src="data.icon" alt="service-icon" class="icon-img" />
    </b-col>
    <b-col class="data-side col-lg-5 col-md-11 col-sm-11">
      <div class="speach">
        <h3>{{ data.name }}</h3>
        <h4>{{ data.provider }}</h4>
        <h5>{{ data.price }} {{ $t('g.realsar') }}</h5>
        <p>{{ $t('g.showService/warning') }}</p>
      </div>
      <div class="actions row">
        <b-button
          variant="primary"
          class="col-lg-5 btn col-md-12 col-sm-12"
          @click="$router.push({ name: 'makePayment', params: { id: data.id } })"
        >
          {{ $t('g.showService/makePayment') }}
        </b-button>
        <b-link to="/fees" class="link col-lg-6 col-md-12 col-sm-12">{{
          $t('g.showServices/link/allfees')
        }}</b-link>
      </div>
    </b-col>
  </div>
</template>
<script>
import { BRow, BCol, BButton, BLink } from 'bootstrap-vue';
export default {
  props: ['data'],
  components: {
    BRow,
    BCol,
    BButton,
    BLink,
  },
  data() {
    return {
      icon: require('@/assets/image/pages/showService/pageHeaderIcon.png'),
    };
  },
};
</script>
<style lang="scss" scoped>
@import './_index';
</style>
