import i18n from "@/libs/i18n";

export default {
  state: {
    appLang: null,
    appDir: 'ltr'
  },
  actions: {
    UPDATE_APP_LANG({ state }, payload) {
      i18n.locale = payload
      state.appLang = payload
      localStorage.setItem('morajea_lang', payload)
      if(payload === 'ar') {
        state.appDir = 'rtl'
        document.documentElement.setAttribute('dir', 'ltr')
      } else {
        state.appDir = 'ltr'
        document.documentElement.setAttribute('dir', 'rtl')
      }
    },
  },
};