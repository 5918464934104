<template>
  <div class="otp-page">
    <pageHeader
      :icon="pageHeader.icon"
      :headlineTranslationKey="pageHeader.headLine"
      :textTranslationKey="pageHeader.text"
    />
    <otpPageComponent />
  </div>
</template>
<script>
import pageHeader from '@/components/shared/pageHeader';
import otpPageComponent from '@/components/otpPageComponent';
export default {
  components: {
    pageHeader,
    otpPageComponent,
  },
  data() {
    return {
      pageHeader: {
        icon: require('@/assets/icons/Frame.svg'),
        text: 'pages_head/text/tracking_fees',
        headLine: 'pages_head/headline/pay_fees',
      },
    };
  },
};
</script>
<style lang="scss">
@import './_index';
</style>
