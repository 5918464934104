<template>
  <b-modal
    id="bv-modal-payment"
    ref="bv-modal-payment"
    centered
    hide-footer
    hide-header
    body-bg-variant="tarnsparent"
    class="payment-model"
    size="lg"
  >
    <div class="p-3 d-flex flex-column">
      <div class="card-1 d-flex flex-column mb-3">
        <div class="card-data w-100">
          <div class="data">
            <h4>
              {{ cardNumber }}
            </h4>
            <h4>mohamed ahmed ragheb</h4>
          </div>
        </div>
        <h4>
          {{ $t('g.otpOverlay/fees') }}
        </h4>
        <h5>20 {{ $t('g.realsar') }}</h5>
      </div>
      <div class="card-2 d-flex flex-column">
        <h4>{{ $t('g.otpOverlay/announce/head') }}</h4>
        <p>
          {{ $t('g.otpOverlay/announce/text') }}
        </p>
      </div>
      <div class="actions w-100 d-flex justify-content-center mt-3">
        <b-link :to="{ name: 'Report' }">
          <b-button variant="primary" class="button" @click="submit"> تسديد الرسوم </b-button>
        </b-link>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { BModal, BButton, BLink } from 'bootstrap-vue';
export default {
  components: {
    BLink,
    BModal,
  },
  data() {
    return {
      name: 'ss',
      imgBg: require('@/assets/images/Frame 98.svg'),
    };
  },
  methods: {
    submit() {
      console.log('submitted');
      this.$bvModal.hide('bv-modal-payment');
    },
  },
  computed: {
    cardNumber() {
      const reqExp = /^(4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$/;
      const number = '4 665555666556656';
      const cleanedNumber = number.replace(/\D/g, '');
      if (reqExp.test(cleanedNumber)) {
        // MasterCard format
        return cleanedNumber.replace(/(\d{4})(?=\d)/g, '$1 ');
      }
    },
  },
};
</script>
<style lang="scss">
@import '~bootstrap/dist/css/bootstrap.css';
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import './_index.scss';
</style>
