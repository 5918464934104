<template>
  <div class="otp-component my-4 row">
    <b-col class="ico-frame col-lg-5 col-md-10 col-sm-10">
      <img :src="icon" alt="otp-icon" class="ico" />
    </b-col>
    <b-col class="speach col-lg-5 col-md-9 col-sm-9">
      <h3>{{ $t('g.otpPage/text') }}</h3>
      <p>{{ $t('g.otpPage/text/helper') }}</p>
      <b-form>
        <b-form-group class="input-group row">
          <b-form-input
            class="col-2 input"
            type="number"
            ref="otp-digits"
            @input="changeDigithandelar()"
            autofocus
          />
          <b-form-input
            class="col-2 input"
            type="number"
            ref="otp-digits"
            @input="changeDigithandelar()"
          />
          <b-form-input
            class="col-2 input"
            type="number"
            ref="otp-digits"
            @input="changeDigithandelar()"
          />
          <b-form-input
            class="col-2 input"
            type="number"
            ref="otp-digits"
            @input="changeDigithandelar()"
          />
          <b-form-input
            class="col-2 input"
            type="number"
            ref="otp-digits"
            @input="changeDigithandelar()"
          />
          <b-form-input
            class="col-2 input"
            type="number"
            ref="otp-digits"
            @input="changeDigithandelar()"
          />
        </b-form-group>
        <div class="actions mt-5 w-100">
          <b-button variant="primary" ref="btn" class="w-50 send-btn" @click="openOverlay"
            >{{ $t('g.otpPage/btns/send') }}
          </b-button>
          <b-link class="link">{{ $t('g.otpPage/btns/resendCode') }}</b-link>
        </div>
      </b-form>
    </b-col>
    <overlay-otp />
  </div>
</template>
<script>
import overlayOtp from '@/components/otpPageComponent/overlayOtp';
import { BCol, BForm, BFormGroup, BFormInput, BButton, BLink, BModal } from 'bootstrap-vue';
export default {
  components: {
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    overlayOtp,
    BLink,
    BModal,
  },
  data() {
    return {
      icon: require('@/assets/icons/Otpicon.svg'),
    };
  },
  methods: {
    openOverlay() {
      this.$bvModal.show('bv-modal-payment');
    },
    changeDigithandelar(ref) {},
  },
};
</script>
<style lang="scss">
@import './index';
</style>
