<template>
  <section class="our-services-section">
    <h2 class="text-title">
      {{ $t('g.home/our_services_section/our_services') }}
    </h2>
    <div class="images-container">
      <img class="image-side" :src="feesImage" alt="fees" />
      <img class="image-side" :src="myTransactionsImage" alt="my transactions" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'OurServicesSection',
  data() {
    return {
      feesImage: require('@/assets/images/home-my-fees.svg'),
      myTransactionsImage: require('@/assets/images/home-my-transactions.svg'),
    };
  },
};
</script>

<style lang="scss">
@import './_index.scss';
</style>
