import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from '@/libs/i18n';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

// change other locale
i18n.locale = 'en';
