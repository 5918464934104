<template>
  <section class="home-container">
    <HeroSection />
    <AboutReferencesSection />
    <OurServicesSection />
    <OurAppSection />
    <LatestTransactionsSection />
  </section>
</template>

<script>
import HeroSection from '@/components/Home/HeroSection';
import AboutReferencesSection from '@/components/Home/AboutReferencesSection';
import OurServicesSection from '@/components/Home/OurServicesSection';
import OurAppSection from '@/components/Home/OurAppSection';
import LatestTransactionsSection from '@/components/Home/LatestTransactionsSection';
import serviceItem from '@/components/shared/serviceItem';

export default {
  name: 'HomeView',
  components: {
    HeroSection,
    AboutReferencesSection,
    OurServicesSection,
    OurAppSection,
    LatestTransactionsSection,
    serviceItem,
  },

  data() {
    return {
      bigImage: require('@/assets/images/Frame 93.svg'),
      smallImage: require('@/assets/images/Group 7.svg'),
      services: {
        icon: require('@/assets/image/pages/myTransactions/icons/traffic-lights.png'),
        name: 'الإدارة العامة للمرور',
        provider: 'وزارة الصحة',
        date: '1444/10/25',
      },
    };
  },
};
</script>

<style lang="scss">
@import './_index.scss';
</style>
