<template>
  <section class="our-app-section">
    <img class="image-side" :src="mobileImage" alt="our apps" />
    <div class="text-container">
      <h2 class="text-title">{{ $t('g.home/our_app_section/get_the_application') }}</h2>
      <p class="text-body">{{ $t('g.home/our_app_section/paragraph') }}</p>
      <div class="images-box">
        <img :src="appleStoreImage" alt="app store" />
        <img :src="playStoreImage" alt="play store" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OurAppSection',
  data() {
    return {
      mobileImage: require('@/assets/images/our-app-image.svg'),
      appleStoreImage: require('@/assets/images/apple-store.svg'),
      playStoreImage: require('@/assets/images/play-store.svg'),
    };
  },
};
</script>

<style lang="scss">
@import './_index.scss';
</style>
