<template>
  <div class="fees-page">
    <b-row class="w-100 m-0">
      <pageHeader
        :icon="pageHead.icon"
        :headlineTranslationKey="pageHead.headlineTranslationKey"
        :textTranslationKey="pageHead.textTranslationKey"
      />
    </b-row>
    <b-row class="servies-list">
      <serviceItem
        v-for="item in services"
        :key="item.name"
        :icon="item.icon"
        :serviceName="item.name"
        :serviceProvider="item.provider"
        :servicePrice="item.price"
        class="col-lg-3 col-md-4 col-sm-12 col-xs-12 m-2"
      />
      <span class="see-more">{{ $t('g.pages/fees/seemore') }}</span>
    </b-row>
  </div>
</template>
<script>
import { BRow, BLink } from 'bootstrap-vue';
import pageHeader from '@/components/shared/pageHeader';
import serviceItem from '@/components/shared/serviceItem';
export default {
  data() {
    return {
      pageHead: {
        icon: require('@/assets/image/pages/fees/pageHeadIcon.png'),
        textTranslationKey: 'pages/fees/pageHead/text',
        headlineTranslationKey: 'pages/fees/pageHead/headline',
      },
      services: [
        {
          icon: require('@/assets/image/pages/fees/icons/health.png'),
          name: 'تأمين عمالة',
          provider: 'وزارة الصحة',
          price: '20',
        },
        {
          icon: require('@/assets/image/pages/fees/icons/badge.png'),
          name: 'بلاغ مقدم',
          provider: 'وزاره الداخلية',
          price: '20',
        },
        {
          icon: require('@/assets/image/pages/fees/icons/traffic-lights.png'),
          name: 'الإدارة العامة للمرور',
          provider: 'وزارة الصحة',
          price: '20',
        },
        {
          icon: require('@/assets/image/pages/fees/icons/health.png'),
          name: 'تأمين عمالة',
          provider: 'وزارة الصحة',
          price: '20',
        },
        {
          icon: require('@/assets/image/pages/fees/icons/traffic-lights.png'),
          name: 'تجديد رخصة قيادة',
          provider: 'الإدارة العامة للمرور',
          price: '20',
        },
        {
          icon: require('@/assets/image/pages/fees/icons/real-estate.png'),
          name: 'تملك عقار',
          provider: 'وزاره الإستثمار',
          price: '20',
        },
      ],
    };
  },
  components: {
    BRow,
    pageHeader,
    serviceItem,
    BLink,
  },
  created() {
    this.$i18n.locale = 'ar';
  },
};
</script>
<style lang="scss">
@import './_index';
</style>
