<template>
  <div class="makePayment">
    <pageHeader
      :icon="pageHeader.icon"
      :textTranslationKey="pageHeader.text"
      :headlineTranslationKey="pageHeader.head"
    />
    <div class="row">
      <makePaymentComponent/>
    </div>
  </div>
</template>
<script>
import pageHeader from '@/components/shared/pageHeader';
import makePaymentComponent from '@/components/makePayment';
export default {
  components: {
    pageHeader,
    makePaymentComponent
  },
  data() {
    return {
      pageHeader: {
        icon: require('@/assets/image/Frame.svg'),
        head: 'pages_head/headline/tracking_fees',
        text: 'pages_head/text/tracking_fees',
      },
    };
  },
};
</script>
<style lang="scss">
@import './_index';
</style>
