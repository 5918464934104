<template>
  <section class="hero-section">
    <div class="images-side">
      <img class="big-image" :src="bigImage" alt="" />
      <img class="small-image" :src="smallImage" alt="" />
    </div>
    <div class="text-side">
      <h3>{{ $t('g.home/hero_section/small_title') }}</h3>
      <h1>
        {{ $t('g.home/hero_section/big_title') }}
        <span class="special-text">{{ $t('g.home/hero_section/special_text') }}</span>
      </h1>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeroSection',
  data() {
    return {
      bigImage: require('@/assets/images/Frame 93.svg'),
      smallImage: require('@/assets/images/Group 7.svg'),
    };
  },
};
</script>

<style lang="scss">
@import './_index.scss';
</style>
