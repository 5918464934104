<template>
  <section class="about-references-section">
    <div class="text-side">
      <h2 class="text-title">
        {{ $t('g.home/about_references_section/about_references') }}
      </h2>
      <h3 class="text-body">{{ $t('g.home/about_references_section/paragraph') }}</h3>
    </div>
    <img class="image-side" :src="sectionImage" alt="" />
  </section>
</template>

<script>
export default {
  name: 'AboutReferncesSection',
  data() {
    return {
      sectionImage: require('@/assets/images/about-references-image.svg'),
    };
  },
};
</script>

<style lang="scss">
@import './_index.scss';
</style>
